import React, { lazy, Suspense } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Switch } from 'react-router-dom';

import { AbsoluteCenter } from '@chakra-ui/react';
import PrivateRoute from './privateRoute';
import ConstantRoutes from './routesArray';
import LoadingSpinner from '../components/loadingSpinner';

const ClientsList = lazy(() => import('../pages/clients/list'));
const OrdersList = lazy(() => import('../pages/orders/list'));

export default function Routes(): React.ReactElement {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
        <>redirect to keycloak...</>
      </AbsoluteCenter>
    );
  }

  return (
    <Suspense
      fallback={
        <AbsoluteCenter>
          <LoadingSpinner />
        </AbsoluteCenter>
      }
    >
      <Switch>
        <PrivateRoute
          roles={['view_clients', 'view_analysis']}
          path="/"
          exact
          component={
            keycloak.resourceAccess?.['trex-application'].roles.includes(
              'view_analysis'
            )
              ? OrdersList
              : ClientsList
          }
        />

        {ConstantRoutes.map((route) => (
          <PrivateRoute
            key={route.path}
            roles={route.roles}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Suspense>
  );
}
