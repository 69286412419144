import { lazy } from 'react';

const Profile = lazy(() => import('../pages/profile'));
const ClientsList = lazy(() => import('../pages/clients/list'));
const ClientsCreate = lazy(() => import('../pages/clients/create'));
const ClientsInfo = lazy(() => import('../pages/clients/info'));
const ListPartners = lazy(() => import('../pages/partners/list'));
const PartnersClientsInfo = lazy(() => import('../pages/partners/clients'));
const ActionsList = lazy(() => import('../pages/actions/list'));
const ActionsListCreate = lazy(() => import('../pages/actions/create'));
const ActionsListUpdate = lazy(() => import('../pages/actions/update'));
const ModelsList = lazy(() => import('../pages/models/list'));
const ModelsCreate = lazy(() => import('../pages/models/create'));
const ModelsUpdate = lazy(() => import('../pages/models/info'));
const ModelsUpdateInfo = lazy(() => import('../pages/models/info/actions'));
const OrdersList = lazy(() => import('../pages/orders/list'));
const QueueManagementList = lazy(() => import('../pages/queueManagement/list'));
const QueuesManagementForm = lazy(
  () => import('../pages/queueManagement/form')
);
const GroupsList = lazy(() => import('../pages/groups/list'));
const GroupsCreate = lazy(() => import('../pages/groups/create'));
const GroupsUpdate = lazy(() => import('../pages/groups/update'));
const QueuesList = lazy(() => import('../pages/queues/list'));
const SystemAnalyzer = lazy(() => import('../pages/systemAnalyzer'));
const OrdersInfo = lazy(() => import('../pages/orders/info/ordersInfoV3'));
const SuspensionList = lazy(() => import('../pages/suspension/list'));
const ClientUpdateAction = lazy(() => import('../pages/clients/info/actions'));
const GroupUpdateloadInfo = lazy(
  () => import('../pages/groups/update/actions')
);
const ActionsListUpdateAction = lazy(
  () => import('../pages/actions/update/actions')
);
const Tags = lazy(() => import('../pages/tags'));
const RulesFlow = lazy(() => import('../pages/rulesFlow/list'));
const RulesFlowCreate = lazy(() => import('../pages/rulesFlow/create'));
const RulesFlowInfo = lazy(() => import('../pages/rulesFlow/info'));
const RulesFlowClientsInfo = lazy(
  () => import('../pages/rulesFlow/info/actions/clients')
);
const RetentionFlow = lazy(() => import('../pages/retentionFlow/list'));
const RetentionFlowCreate = lazy(() => import('../pages/retentionFlow/create'));
const RetentionFlowInfo = lazy(() => import('../pages/retentionFlow/info'));
const Blocks = lazy(() => import('../pages/blocks/list'));
const BlocksCreate = lazy(() => import('../pages/blocks/create'));
const BlocksInfo = lazy(() => import('../pages/blocks/info'));
const PartnersInfo = lazy(() => import('../pages/partners/info'));
const PartnersCreate = lazy(() => import('../pages/partners/create'));
const CommentsFlow = lazy(
  () => import('../pages/rulesFlow/info/actions/comments')
);
const RetentionFlowClientsInfo = lazy(
  () => import('../pages/retentionFlow/info/actions/clients')
);

const RoutesArray = [
  // #region orders
  {
    roles: ['view_analysis'],
    path: '/orders/info/:id',
    component: OrdersInfo,
    exact: true,
  },

  {
    roles: ['view_analysis'],
    path: '/orders/info/v3/:id',
    component: OrdersInfo,
    exact: true,
  },

  {
    roles: ['view_system_metrics'],
    path: '/system-metrics',
    component: SystemAnalyzer,
    exact: true,
  },

  {
    roles: ['view-profile'],
    path: '/profile',
    component: Profile,
    exact: true,
  },
  // #endregion

  // #region clients
  {
    roles: ['view_clients'],
    path: '/client',
    component: ClientsList,
    exact: true,
  },
  {
    roles: ['create_clients'],
    path: '/client/create',
    component: ClientsCreate,
    exact: true,
  },
  {
    roles: ['view_clients', 'modify_clients'],
    path: '/client/info',
    component: ClientsInfo,
    exact: true,
  },
  {
    roles: ['view_clients', 'modify_clients'],
    path: '/client/info/list',
    component: ClientUpdateAction,
    exact: true,
  },
  // #endregion

  // #region rules flow
  {
    roles: ['view_flows'],
    path: '/rules-flow',
    component: RulesFlow,
    exact: true,
  },
  {
    roles: ['create_flows'],
    path: '/rules-flow/create',
    component: RulesFlowCreate,
    exact: true,
  },
  {
    roles: ['view_flows', 'modify_flows'],
    path: '/rules-flow/info/:id',
    component: RulesFlowInfo,
    exact: true,
  },
  {
    roles: ['view_flows', 'modify_flows'],
    path: '/rules-flow/info/clients/:id',
    component: RulesFlowClientsInfo,
    exact: true,
  },
  {
    roles: ['view_flows', 'modify_flows'],
    path: '/rules-flow/info/comments/:id',
    component: CommentsFlow,
    exact: true,
  },

  // #endregion

  // #region retention flow
  {
    roles: ['view_retention_flows'],
    path: '/retention-flow',
    component: RetentionFlow,
    exact: true,
  },
  {
    roles: ['create_retention_flows'],
    path: '/retention-flow/create',
    component: RetentionFlowCreate,
    exact: true,
  },
  {
    roles: ['view_retention_flows', 'modify_retention_flows'],
    path: '/retention-flow/info/:id',
    component: RetentionFlowInfo,
    exact: true,
  },
  {
    roles: ['view_retention_flows', 'modify_retention_flows'],
    path: '/retention-flow/info/clients/:id',
    component: RetentionFlowClientsInfo,
    exact: true,
  },
  // #endregion

  // #region blocks
  {
    roles: ['view_blocks'],
    path: '/blocks',
    component: Blocks,
    exact: true,
  },

  {
    roles: ['create_blocks'],
    path: '/blocks/create',
    component: BlocksCreate,
    exact: true,
  },

  {
    roles: ['view_blocks', 'modify_blocks'],
    path: '/blocks/info/:id',
    component: BlocksInfo,
    exact: true,
  },

  // #endregion

  // #region partners
  {
    roles: ['view_partners'],
    path: '/partners',
    component: ListPartners,
    exact: true,
  },
  {
    roles: ['create_partners'],
    path: '/partners/create',
    component: PartnersCreate,
    exact: true,
  },
  {
    roles: ['view_partners', 'modify_partners'],
    path: '/partners/info',
    component: PartnersInfo,
    exact: true,
  },

  {
    roles: ['view_partners', 'modify_partners'],
    path: '/partners/info/list',
    component: PartnersClientsInfo,
    exact: true,
  },

  // #endregion

  // #region actions
  {
    roles: ['view_actions'],
    path: '/actions-list/',
    component: ActionsList,
    exact: true,
  },

  {
    roles: ['create_actions'],
    path: '/actions-list/create',
    component: ActionsListCreate,
    exact: true,
  },
  {
    roles: ['view_actions', 'modify_actions'],
    path: '/actions-list/info',
    component: ActionsListUpdate,
    exact: true,
  },
  {
    roles: ['view_actions', 'modify_actions'],
    path: '/actions-list/info/list',
    component: ActionsListUpdateAction,
    exact: true,
  },
  // #endregion

  // #region models
  {
    roles: ['view_models'],
    path: '/models',
    component: ModelsList,
    exact: true,
  },
  {
    roles: ['create_models'],
    path: '/models/create',
    component: ModelsCreate,
    exact: true,
  },

  {
    roles: ['view_models', 'modify_models'],
    path: '/models/info/:id',
    component: ModelsUpdate,
    exact: true,
  },

  {
    roles: ['view_models', 'modify_models'],
    path: '/models/info/clients/:id',
    component: ModelsUpdateInfo,
    exact: true,
  },
  // #endregion

  // #region analisys
  {
    roles: ['view_analysis'],
    path: '/orders',
    component: OrdersList,
    exact: true,
  },
  {
    roles: ['view_analysis'],
    path: '/filas',
    component: QueuesList,
    exact: true,
  },
  {
    roles: ['resolve_suspension'],
    path: '/suspensions',
    component: SuspensionList,
    exact: true,
  },
  // {
  //   roles: ['view_analysis'],
  //   path: '/orders/info/:id',
  //   component: OrdersInfo,
  //   exact: true,
  // },
  // #endregion

  // #region queues
  {
    roles: ['view_queues'],
    path: '/gestao-de-filas',
    component: QueueManagementList,
    exact: true,
  },
  {
    roles: ['create_queues'],
    path: '/gestao-de-filas/create',
    component: QueuesManagementForm,
    exact: true,
  },
  {
    roles: ['view_queues', 'modify_queues'],
    path: '/gestao-de-filas/info',
    component: QueuesManagementForm,
    exact: true,
  },
  // #endregion

  // #region groups
  {
    roles: ['view_groups'],
    path: '/groups',
    component: GroupsList,
    exact: true,
  },
  {
    roles: ['create_groups'],
    path: '/groups/create/',
    component: GroupsCreate,
    exact: true,
  },
  {
    roles: ['view_groups', 'modify_groups'],
    path: '/groups/info',
    component: GroupsUpdate,
    exact: true,
  },
  {
    roles: ['view_groups', 'modify_groups'],
    path: '/groups/info/list',
    component: GroupUpdateloadInfo,
    exact: true,
  },

  // #endregion

  // #region tags
  {
    roles: ['view_tags'],
    path: '/tags',
    component: Tags,
    exact: true,

    // #endregion
  },
];

export default RoutesArray;
