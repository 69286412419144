import { toast } from 'react-toastify';
import { Route, Redirect } from 'react-router-dom';

import * as I from '../interfaces/routes';

import checkRoles from '../utils/checkRoles';
import generateUUID from '../utils/generateUUID';
import { SETID } from '../services/storage';

const PrivateRoute = ({
  component: Component,
  roles,
  ...rest
}: I.PrivateRoute) => {
  const hasRole = checkRoles(roles);
  const UUID = generateUUID();
  localStorage.setItem(SETID, UUID);

  if (!hasRole) {
    toast.error(`Você não possui acesso a este recurso`);
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => <Component {...routeProps} {...rest} />}
    />
  );
};

PrivateRoute.defaultProps = {
  exact: true,
};

export default PrivateRoute;
