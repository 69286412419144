import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import useModels from '../../hooks/api/models';
import { emptyModelsObject } from './utils';

import * as I from './interfaces';
import * as I2 from '../../interfaces/models';

const RulesModelsContext = createContext<I.Context>({} as I.Context);

const RulesModelsProvider = ({
  children,
}: I.ProviderProps): React.ReactElement => {
  const { getOneModel, getAllFeatures } = useModels();
  const [modelsData, setModelsData] =
    useState<I2.ModelsModel>(emptyModelsObject);
  const [featuresData, setFeaturesData] = useState<I2.FeaturesModel[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModelsLoaded, setIsModelsLoaded] = useState(false);
  const [isFeaturesLoaded, setIsFeaturesLoaded] = useState(false);

  const { keycloak } = useKeycloak();
  const { authenticated } = keycloak;

  const location = useLocation();
  const splitedPath = location.pathname.split('/');
  const id = splitedPath.includes('info')
    ? splitedPath[splitedPath.length - 1]
    : '';

  const handleGetModels = async (): Promise<void> => {
    if (id) {
      try {
        setIsModelsLoaded(false);
        const response = await getOneModel(id);
        setModelsData(response.data);
      } finally {
        setIsModelsLoaded(true);
      }
    }
    setIsModelsLoaded(true);
  };

  const handleGetRules = async (): Promise<void> => {
    try {
      setIsFeaturesLoaded(false);
      const response = await getAllFeatures();
      setFeaturesData(response.data.items);
    } finally {
      setIsFeaturesLoaded(true);
    }
  };

  const handleDisabled = async (): Promise<void> => {
    setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    const isInfoOrCreate =
      splitedPath.includes('info') || splitedPath.includes('create');

    if (splitedPath.includes('models') && isInfoOrCreate && authenticated) {
      handleGetModels();
      handleGetRules();
    }
  }, [id, authenticated]);

  useEffect(() => {
    setIsLoaded(Boolean(isModelsLoaded && isFeaturesLoaded && authenticated));
  }, [isModelsLoaded, isFeaturesLoaded, authenticated]);

  return (
    <RulesModelsContext.Provider
      value={useMemo(
        () => ({
          dataContext: modelsData,
          isLoaded,
          features: featuresData || [],
          featuresNames: featuresData?.map((feature) => feature.name) || [],
          hasID: id,
          handleDisabled,
          setIsDisabled,
          getNewVersion: handleGetModels,
          isDisabled,
        }),
        [isLoaded, isDisabled]
      )}
    >
      {children}
    </RulesModelsContext.Provider>
  );
};

function useRulesModels(): I.Context {
  const context = useContext(RulesModelsContext);
  const {
    dataContext,
    isLoaded,
    isDisabled,
    features,
    featuresNames,
    hasID,
    handleDisabled,
    setIsDisabled,
    getNewVersion,
  } = context;
  return {
    dataContext,
    isLoaded,
    isDisabled,
    features,
    featuresNames,
    hasID,
    handleDisabled,
    setIsDisabled,
    getNewVersion,
  };
}

export { RulesModelsProvider, useRulesModels };
